<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>

    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="listData" v-model:selection="selectedRow" selectionMode="multiple"
                    :metaKeySelection="false" :filters="listFilters" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="globalFilterField" scrollable :tableStyle="styleHeight" :scrollHeight="sHeight"
                    responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true" rows="50"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearListFilter()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="listFilters['global'].value" :placeholder="globalPlaceHolderTitle"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of pegeConfig.listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data[col.field]"
                                @click="openDetail(slotProps.data)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="displayDetailWin" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <UniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoadingFun" :currentRow="currentRow"
                :freshListMethod="freshListFun" :getChildTableColWidthMehod="getChildTableColWidthFun"
                :closeParentMehod="closeDetailWinFun" :CellEditCompleteMethod="cellEditCompleteFun"
                :childNewDataConfig="childNewDataConfig" :childExpandePageConfig="childExpandeConfig"
                :childTableFooter="childTableFooter" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openFlowHistoryWin" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeDetailWinFun" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlanFun(false)"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayFlowHistory" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closeFlowHistoryWin" autofocus />
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import UniversalPage from '../../../components/UniversalPage.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';

    export default {
        setup() {
            const confirm = useConfirm();
            const pegeConfig = ref();
            return {
                confirm,
                pegeConfig
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                sHeight: '600px',
                styleHeight: '',
                globalPlaceHolderTitle: '',
                loading: true,
                totalRecords: 0,
                readOnly: false,
                first: 0,
                butLoading: false,
                currentState: '流程信息',
                recordsubject: '',
                displayDetailWin: false,
                displayQueryPlan: false,
                displayFlowHistory: false,
                position: 'bottomleft',
                currentRow: ref(),
                flowhistoryJson: ref(),
                items: [{
                    label: '操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createData();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delData();
                            }
                        }
                    ]
                }],
                expandedKey: ref(),
                nodes: [],
                selectedKey: ref(),
                listData: [],
                listFilters: {},
                listTitle: "",
                selectedRow: ref(),
                flowId: "",
                startStateId: "",
                globalFilterField: [],
                childNewDataConfig: {},
                childTableFooter: {},
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: '',
                    queryconfig: '',
                },
            }
        },
        created() {
            MessageTip.initMsg();
            this.initPageCofig();
            this.globalPlaceHolderTitle = this.listTitle + "模糊查询";
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
        },
        methods: {
            initPageCofig() {
                var pageConfigId = CommonJs.getUrlParam('configid');
                console.log(pageConfigId);
                this.pegeConfig = {
                    currentMenuTitle: "",
                    currentMenuId: "",
                    currentMenuName: "",
                    bindPage: "",
                    flowGuid: "",
                    globalQueryCol: "",
                    childExpandeConfig: {},
                    detailTitleCol: "",
                    listcolumns: [{
                        field: '',
                        header: '',
                        bodyStyle: '',
                        headerStyle: '',
                        showFilterOperator: false,
                        filterMatchModeOptions: {},
                        showFilterMenu: false,
                        isimage: true,
                        frozen: true,
                        sortable: true,
                        islink: true,
                        istime: false,
                        isnum: false,
                        oneline: true,
                    }],
                    childConfig: {
                        newDataConfig: {
                            products: false,
                        },
                        tableFooter: {
                            products: {
                                url: '合计',
                                loadingqty: 0,
                                price: 0,
                                grossweight: 0,
                                netweight: 0
                            }
                        }
                    },
                };
                for (var i = 0; i < this.pegeConfig.listcolumns.length; i++) {
                    this.pegeConfig.listcolumns[i].filterMatchModeOptions = this.matchModes;
                    if (this.pegeConfig.listcolumns[i].isnum) {
                        this.pegeConfig.listcolumns[i].filterMatchModeOptions = this.numMatchModes;
                    }
                    if (this.pegeConfig.listcolumns[i].istime) {
                        this.pegeConfig.listcolumns[i].filterMatchModeOptions = this.timeMatchModes;
                    }
                }
                this.queryPlanObj.parentid = this.pegeConfig.currentMenuId;
            },
            initchildTableFooter() {
                this.childTableFooter = this.pegeConfig.childConfig.tableFooter;
            },
            initListFilters() {
                CommonJs.initFilters(this.listFilters, this.pegeConfig.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters) {
                this.listFilters = v_filters;
            },
            clearListFilter() {
                this.initListFilters();
                this.loadListData(1);
            },
            customFilter() {
                setTimeout(() => {
                    this.loadListData(1);
                }, 10);
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, this.listTitle, this.loadListData,
                    this.setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            closeButLoadingFun() {
                this.butLoading = false;
            },
            closeDetailWinFun() {
                this.displayDetailWin = false;
            },
            getChildTableColWidthFun(v_childname, v_fieldname) {
                console.log(v_childname);
                console.log(v_fieldname);
                return 'width:150px;';
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            openDetail(v_data) {
                if (v_data.currentstatename)
                    this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = this.listTitle + v_data[this.pegeConfig.detailTitleCol];
                this.currentRow = {
                    bindpage: this.pegeConfig.bindPage,
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: this.pegeConfig.flowGuid,
                    boxid: v_data.boxid,
                    stateid: v_data.stateid,
                    flowid: v_data.flowid,
                };
                this.displayDetailWin = true;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            createData() {
                this.recordsubject = '新建' + this.listTitle;
                this.initchildTableFooter();
                this.currentRow = {
                    bindpage: this.pegeConfig.bindPage,
                    id: '-1',
                    workflowid: '',
                    flowguid: this.pegeConfig.flowGuid,
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayDetailWin = true;
            },
            delData() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除的数据');
                    return;
                }
                var ids = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    ids.push({
                        "id": this.selectedRow[k1]["id"]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "delete{" + this.pegeConfig.bindPage + "list(o:" + JSON.stringify(ids) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadListData(1);
                        MessageTip.successmsg('删除成功');
                    } else {
                        MessageTip.warnmsg('删除失败');
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                this.loadListData(page + 1);
            },
            loadListData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: this.pegeConfig.currentMenuName,
                        value: '',
                        operation: '='
                    }]
                };
                var globalFilterColStr = "";
                for (var i2 = 0; i2 < this.globalFilterField.length; i2++) {
                    if (globalFilterColStr == "") {
                        globalFilterColStr = this.globalFilterField[i2];
                    } else {
                        globalFilterColStr = globalFilterColStr + "," + this.globalFilterField[i2];
                    }
                }
                for (var key in this.listFilters) {
                    if (key == 'global' && this.listFilters[key]['value']) {
                        listwhere.conditions.push({
                            name: globalFilterColStr,
                            value: this.listFilters[key]['value'],
                            operation: this.listFilters[key]['matchMode']
                        });
                    }
                    if (this.listFilters[key]['constraints']) {
                        for (var i = 0; i < this.listFilters[key].constraints.length; i++) {
                            if (this.listFilters[key].constraints[i].value && this.listFilters[key].constraints[i]
                                .value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.listFilters[key].constraints[i].value,
                                    operation: this.listFilters[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{purchaselist(where:" + JSON.stringify(listwhere) +
                    "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.purchases = jsonData.data.purchaselist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            freshListFun() {
                this.butLoading = false;
                this.loadListData(1);
            },
            openFlowHistoryWin() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayFlowHistory = true;
            },
            closeFlowHistoryWin() {
                this.displayFlowHistory = false;
            },
            cellEditCompleteFun(tablename, field, data, rowdata, childTableFooterObj) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);

            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
        components: {
            UniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>